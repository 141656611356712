export function biliLinkToIframe(biliLink, minute, second, iframe) {
    let biliIframe
    let videoSrc
    let linkWithSecond
    if (iframe) { // 存储了嵌入代码
        biliIframe = iframe
    } else { // 没有存储嵌入代码，自己转换
        try {
            const bv = biliLink.match(/(BV.*?).{10}/)[0]
            let p
            try {
                p = biliLink.match(biliLink.match(/p=(\d+)/)[1]);
            } catch (error) {
                p = null
            }
            let result = "player.bilibili.com/player.html?bvid=" + bv
            // https://www.dismall.com/thread-21240-1-1.html
            // let result = "//www.bilibili.com/blackboard/html5mobileplayer.html?bvid=" + bv
            if (p) {
                result += "&page=" + p
            }
            biliIframe = '<iframe src="//' + result
            biliIframe += '" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>'
        } catch (e) {
            console.log("嵌入链接转换失败:" + e)
        }
    }
    if (biliIframe) {
        let total_second = 60 * minute + second
        if (total_second === 0) {
            total_second = 0.1
        }
        let tmp = ''
        try {
            let reg = /src="(.*?)"/;
            tmp = reg.exec(biliIframe)[1].trim();
            videoSrc = tmp + '&high_quality=1&autoplay=0' + '&t=' + total_second.toString()
        } catch (err) {
            console.log('视频信息获取失败')
        }
        if (biliLink.indexOf('?') === -1) {
            linkWithSecond = biliLink + '?t=' + total_second.toString()
        } else {
            linkWithSecond = biliLink + '&t=' + total_second.toString()
        }
    }
    return [videoSrc, linkWithSecond]
}